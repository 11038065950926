export const DEFAULT_TIMEZONE = 'JST';

export const TIME_ZONE = [
  {
    text: 'Japan (GMT +9) Tokyo',
    value: 'JST',
  },
  {
    text: 'India (GMT+5:30) Kolkata',
    value: 'IST',
  },
];

export const DATE_FORMATE = [
  {
    text: '2023/08/15 -JST',
    value: 'JST',
  },
  {
    text: '2023/08/15 -IST',
    value: 'IST',
  },
];

export const LEAVE_QUESTIONS = [
  {
    id: 1,
    value: 'To go abroad such as study abroad or work holiday',
  },
  {
    id: 2,
    value: 'the functions of young brain are difficult for use',
  },
  {
    id: 3,
    value: 'There are few companies listed on Young Brain',
  },
];
