export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/logo.png';
export const BANNER = '/banner.jpeg';
export const ONBOARDING_ICON = '/onboardingIcon.png';
export const ONBOARDING_ICON_2 = '/onboarding-2.svg';
export const PROFILE_IMAGE = '/profile-avatar.png';
export const ONBOARDING_PROGILE_BG = '/profile-bg.png';
export const COMPANY_TOP_BANNER = '/dashboard-bg.png';
export const COMPANY_LOGO = '/icon-1.png';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const PROFILE_BANNER_IMAGE = '/_banner-company.jpg';
export const SUCCESS_RIBBON_IMAGE = '/success-ribbon.png';
export const QR_LOGO = '/sample-qr.png';
export const FEEDBACK_IMAGE = '/Group 1054.png';
export const COMPANY_EMPTY_ICON = '/images/company-empty-icon.png';
export const UNIVERSITY_ICON = '/university-icon.png';
export const NEW_CHAT_ICON = '/images/new-chatIcon.svg';
export const ARROW_RIGHT = '/arrow-right.png';
export const ARROW_LEFT = '/arrow-left.png';
//Profle Related Images
export const DEFAULT_CAREER_OFFICE_TOPIC_BANNER =
  '/images/career-office-topic-banner.jpg';
