import { STUDENT_STATUS_PROFILE_UPDATED } from 'utils/constants/student';

export const updateStudentStatus = data => {
  const { status: authStatus, student } = data;
  const { status: studentStatus } = student;

  let status = authStatus;
  if (studentStatus === STUDENT_STATUS_PROFILE_UPDATED) status = studentStatus;

  return status;
};
