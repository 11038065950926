//App
export const APP = '/app';
export const HOME = '/student';

//Job
// export const JOB = '/student/job';
export const JOB = '/student/job/public-jobs';
export const COMPANY_DETAILS_PUBLIC_JOB =
  '/student/job/public-jobs/company-details';
export const COMPANY_DETAILS_UNIVERSITY_JOB =
  '/student/job/university-jobs/company-details';
export const JOB_UNIVERSITY = '/student/job/university-jobs';
export const JOB_HOME = '/student/job/public-jobs/?tab=0';
export const APPLIED_JOBS = '/student/job?tab=1';
export const INTERVIEWS = '/student/job?tab=2';
export const JOB_OFFERS = '/student/job?tab=3';
export const JOB_DESCRIPTION_DETAIL = '/student/job/public-jobs/details';
export const UNIVERSITY_JOB_DESCRIPTION_DETAIL =
  '/student/job/university-jobs/details';
export const INTERVIEW_CARD_DETAIL = '/student/job/interview/details';
export const UNOFFICIAL_OFFER_LETTER = '/student/job/offer-letter';

//Seminar
// export const SEMINAR = '/student/seminar';
export const SEMINAR = '/student/seminar/public-seminar';
export const SEMINARFROMUINIVERSITY = '/student/seminar/university-seminar';
export const SEMINAR_HOME = '/student/seminar?tab=0';
export const REGISTERED_SEMINAR = '/student/seminar?tab=1';
export const ATTENDED_SEMINAR = '/student/seminar?tab=2';
export const SEMINAR_DESCRIPTION =
  '/student/seminar/public-seminar/description';
export const UNIVERSITY_SEMINAR_DESCRIPTION =
  '/student/seminar/university-seminar/description';
export const COMPANY_DETAILS_PUBLIC_SEMINAR =
  '/student/seminar/public-seminar/company-details';
export const COMPANY_DETAILS_UNIVERSITY_SEMINAR =
  '/student/seminar/university-seminar/company-details';
// export const SEMINAR_DESCRIPTION = '/student/seminar/description';
export const SEMINAR_FEEDBACK = '/student/seminar/feedback';

//Dashboard
export const DASHBOARD = '/student/dashboard';
export const PROFILE_VIEWED = '/student/dashboard/companies/viewed-profile';
export const COMPANY_PROFILE = '/student/dashboard/companies';

// Career Office
export const CAREER_OFFICE = `${HOME}/career-office`;
export const CAREER_OFFICE_COUNSELLORS = `${CAREER_OFFICE}/counsellors`;
export const COUNSELLORS_DETAILS = `${CAREER_OFFICE_COUNSELLORS}/details`;
export const COUNSELLORS_APPOINTMENT = `${CAREER_OFFICE_COUNSELLORS}/book-appointment`;
export const CAREER_OFFICE_UNIV_PROFILE = `${CAREER_OFFICE}/university-profile`;

// Universty sponsors
export const SPONSORS = `${CAREER_OFFICE_UNIV_PROFILE}/sponsors`;
export const SPONSORS_COMPANY_DETAILS = `${SPONSORS}/company`;

export const CHAT = '/student/chat';
export const COMPANY_CHAT = '/student/chat/company';

//Courses
export const COURSES = '/student/courses';

//Favorites
export const FAVORITES = '/student/favorites';
export const FAVORITE_JOBS = '/student/favorites?tab=0';
export const FAVORITE_SEMINARS = '/student/favorites?tab=1';
export const FAVORITE_SEMINAR_DESCRIPTION =
  '/student/favorites/seminar/description';
export const FAVORITE_JOB_DESCRIPTION = '/student/favorites/job/description';

//Notification
export const NOTIFICATION = '/student/notification';

//Profile
export const PROFILE = '/student/profile';
export const EDIT_PROFILE = '/student/profile/edit';
export const STUDENT_RESUME = '/student/profile/resume';
export const STUDENT_RESUME_PREVIEW = '/student/profile/resume/preview';

// Help and Feedback
export const SETTINGS = '/student/settings';

//Setting
export const HELP_AND_FEEDBACK = '/student/help-and-feedback';

//Signout
export const SIGNOUT = '/app/signout';

//Auth
export const LOGIN = '/app/login';
export const REGISTER = '/app/register';
export const CREATE_PROFILE = '/app/create-profile';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';
export const CREATE_PASSWORD = '/app/create-password';
