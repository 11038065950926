import { DEFAULT_LANGUAGE } from 'utils/constants/app';
import { LS_LANGUAGE } from 'utils/constants/localStorage';

export const noop = () => {};

export const paginationCounter = (total = 0, noOfPageItems = 30) => {
  return Math.ceil(total / noOfPageItems);
};

export const getLanguageCode = () => {
  return localStorage.getItem(LS_LANGUAGE) || DEFAULT_LANGUAGE;
};
