import sidebar from './sidebar.json';
import app from './app.json';
import pages from './pages';

const ja = {
  sidebar,
  app,
  pages,
};

export default ja;
