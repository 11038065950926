export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;
export const YOUNG_BRAIN_API_ENDPOINT =
  process.env.REACT_APP_YOUNG_BRAIN_API_ENDPOINT;
export const COMMON_UTILES_API_ENDPOINT =
  process.env.REACT_APP_COMMON_UTILES_END_POINTS;
export const authBaseEndpoint = api => AUTH_API_ENDPOINT + api;
export const youngbrainBaseEndpoint = api => YOUNG_BRAIN_API_ENDPOINT + api;
export const commonUtilesBaseEndpoint = api => COMMON_UTILES_API_ENDPOINT + api;

// Dashboard Endpoints
export const jobDashboardApi = (user, params = '') =>
  youngbrainBaseEndpoint(`/jobs-dashboard/${user}`) + params;
export const seminarDashboardApi = user =>
  youngbrainBaseEndpoint(`/seminar-dashboard/${user}`);
export const latestJobsApi = () => youngbrainBaseEndpoint('/job/all');
export const latestSeminarsApi = () => youngbrainBaseEndpoint('/seminar/all');

//Company Endpoints
export const companiesApi = () => youngbrainBaseEndpoint('/company/all');

//Job Endpoints
export const getJobsApi = (params = '') =>
  youngbrainBaseEndpoint('/univ/5/job/all') + params;
export const getPubJobsApi = (params = '') =>
  commonUtilesBaseEndpoint('/job/all') + params;
export const getUniversityJobsApi = (unid, params = '') =>
  commonUtilesBaseEndpoint(`/univ/${unid}/job/all`) + params;
// export const getJobsApi = (params = '') =>
//   youngbrainBaseEndpoint('/job/all') + params;
export const getAppliedJobsApi = (params = '') =>
  youngbrainBaseEndpoint('/jobApplications/all') + params;
export const getJobDescriptionByIdApi = id =>
  youngbrainBaseEndpoint(`/jobs/job-description/${id}`);
export const applyForJobApplicationApi = () =>
  youngbrainBaseEndpoint('/jobs/apply');
export const getJobLocationsApi = () =>
  youngbrainBaseEndpoint('/job-locations');
export const getJobTechnologiesApi = () => youngbrainBaseEndpoint('/job-types');

//Interview Endpoints
export const getInterviewsApi = (params = '') =>
  youngbrainBaseEndpoint('/interviews/all') + params;
export const updateJobInterviewApi = (params = '') =>
  youngbrainBaseEndpoint('/job-interview/') + params;
export const rescheduleInterviewApi = () =>
  youngbrainBaseEndpoint('/interviews/reschedule');
export const acceptInterviewApi = () =>
  youngbrainBaseEndpoint('/interviews/accept');
export const rejectInterviewApi = () =>
  youngbrainBaseEndpoint('/interviews/reject');

//Job Offer Letter Endpoints
export const getJobOfferLettersApi = (params = '') =>
  youngbrainBaseEndpoint('/job-offers/all') + params;
export const updateJobOfferStatusApi = (params = '') =>
  youngbrainBaseEndpoint('/job-offer/' + params);
export const submitFeedbackApi = () =>
  youngbrainBaseEndpoint('/job-offer/feedback');

//Favorites Endpoints
export const toggleFavoriteJobApi = () =>
  youngbrainBaseEndpoint('/job/favourite');
export const getAllFavoriteJobsApi = () =>
  youngbrainBaseEndpoint('/favorite/jobs');
export const updateFavoriteSemiarApi = () =>
  youngbrainBaseEndpoint('/seminar/favourite');
export const getFavoriteSeminarApi = () =>
  youngbrainBaseEndpoint('/favorite/seminars');

//Seminar Endpoints
export const getSeminarInvitesApi = (params = '') =>
  commonUtilesBaseEndpoint('/saved/seminar/findall') + params;
export const getSeminarInvitesByApi = id =>
  commonUtilesBaseEndpoint(`/seminar/${id}`);

export const getUniveritySeminarInvitesApi = (univId, params = '') =>
  commonUtilesBaseEndpoint(`/univ/${univId}/saved/seminar/findAll`) + params;

export const getRegisteredSSeminarApi = (params = '') =>
  youngbrainBaseEndpoint('/seminar/applications/all') + params;
export const getAttendedSeminarsApi = (params = '') =>
  youngbrainBaseEndpoint('/seminar/applications/all') + params;
export const getSeminarDetailsByIdApi = seminarId =>
  youngbrainBaseEndpoint(`/seminars/${seminarId}`);
export const seminarRegistrationApi = () =>
  youngbrainBaseEndpoint('/seminar/register');
export const updateSeminarApi = (id = '') =>
  youngbrainBaseEndpoint('/seminar/update/' + id);

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('/auth/signin');
export const signupUserApi = () => authBaseEndpoint('/auth/signup');
export const forgotUserPasswordApi = () =>
  youngbrainBaseEndpoint('/auth/forgotPassword');
export const resetUserPasswordApi = () =>
  youngbrainBaseEndpoint('/auth/resetPasswordLink');
export const editStudentProfileApi = user =>
  youngbrainBaseEndpoint(`/students/edit-profile/${user}`);
export const getStudentProfileApi = user =>
  authBaseEndpoint(`/students/${user}`);
export const getAllCoursesApi = (params = '') =>
  youngbrainBaseEndpoint('/course/all') + params;

// Setting Endpoints
export const getUserSettingsApi = user =>
  youngbrainBaseEndpoint(`/settings/${user}`);
export const updateUserSettingsApi = (params = '') =>
  youngbrainBaseEndpoint('/settings') + params;

//Notification Endpoint
export const getJobNotificationApi = user =>
  youngbrainBaseEndpoint(`/notification/${user}/job`);
export const getSeminarNotificationApi = user =>
  youngbrainBaseEndpoint(`/notification/${user}/seminar`);
export const deleteNotificationApi = user =>
  youngbrainBaseEndpoint(`/notification/clear/${user}`);
//Profile Endpoints
export const getCertificateIssuingOrgListApi = () =>
  youngbrainBaseEndpoint('/certificate-organization-list');
export const getCertificationListApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
export const getFacultyDepartmentListApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
export const getSchoolUniversityListApi = () =>
  youngbrainBaseEndpoint('/school-university-list');
